import React, { useState } from 'react';
import {
  Box, Grid, TextField, Select, MenuItem, Typography, Paper,
  FormControl, InputLabel, FormControlLabel, Modal, Button
} from '@mui/material';
import { RadioGroup, Radio, Tabs, Tab } from '@mui/material';
import { Person, Info, Contacts, ListAlt } from '@mui/icons-material';
import {
  
  Event,
  Settings,
  Description,
  CheckCircle,
  Folder,
  Notifications,
  AttachMoney,
} from '@mui/icons-material';

const NovaTarefa = () => {
  const [selectedOption, setSelectedOption] = useState('obrigacoes'); // Opção atual
  const [pendingOption, setPendingOption] = useState(null); // Nova opção pendente
  const [openModal, setOpenModal] = useState(false); // Controle do modal
  const [checklistItems, setChecklistItems] = useState([]); // Estado para os itens do checklist
const [newStep, setNewStep] = useState(""); // Estado para um novo passo
const [folders, setFolders] = useState([
  { id: "root", name: "Root", files: [], subfolders: [] },
]); 

// Estrutura de pastas
const [currentFolderId, setCurrentFolderId] = useState("root"); // Navegação atual
const [newFolderName, setNewFolderName] = useState(""); // Para adicionar nova pasta
const [newFileName, setNewFileName] = useState(""); // Para upload de novo arquivo
const [pdfData, setPdfData] = useState(null); // Armazena os dados extraídos do PDF
const [loading, setLoading] = useState(false); // Controle de carregamento


  const handleRadioChange = (event) => {
    const newValue = event.target.value; // Captura a nova seleção
    setPendingOption(newValue); // Armazena temporariamente a nova seleção
    setOpenModal(true); // Abre o modal para confirmar
  };
  

 const handleAddStep = () => {
  if (newStep.trim()) {
    setChecklistItems([...checklistItems, newStep.trim()]);
    setNewStep(""); // Limpa o campo de entrada
  }
};



// Adicionar nova pasta
const handleAddFolder = () => {
  if (newFolderName.trim()) {
    const folder = findFolderById(currentFolderId, folders);
    folder.subfolders.push({
      id: Date.now().toString(),
      name: newFolderName.trim(),
      files: [],
      subfolders: [],
    });
    setFolders([...folders]);
    setNewFolderName("");
  }
};

// Adicionar novo arquivo
const handleAddFile = () => {
  if (newFileName.trim()) {
    const folder = findFolderById(currentFolderId, folders);
    folder.files.push({ id: Date.now().toString(), name: newFileName.trim() });
    setFolders([...folders]);
    setNewFileName("");
  }
};


const handleTestFileUpload = async (event) => {
  const file = event.target.files[0];
  if (!file) {
    alert("Por favor, selecione um arquivo.");
    return;
  }

  const formData = new FormData();
  formData.append("file", file);

  setLoading(true);

  try {
    const response = await fetch("https://ai.conttalk.com.br/api/upload-pdf", {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Erro ao processar o arquivo.");
    }

    const result = await response.json();
    setPdfData(result); // Salva os dados no estado
  } catch (error) {
    console.error("Erro ao fazer upload:", error);
    alert("Erro ao processar o arquivo.");
  } finally {
    setLoading(false);
  }
};




// Remover pasta ou arquivo
const handleRemoveItem = (itemId, type) => {
  const folder = findFolderById(currentFolderId, folders);
  if (type === "folder") {
    folder.subfolders = folder.subfolders.filter((sub) => sub.id !== itemId);
  } else {
    folder.files = folder.files.filter((file) => file.id !== itemId);
  }
  setFolders([...folders]);
};

// Função para encontrar uma pasta pelo ID
const findFolderById = (id, folders) => {
  for (const folder of folders) {
    if (folder.id === id) return folder; // Retorna a pasta encontrada
    const found = findFolderById(id, folder.subfolders); // Busca recursiva em subpastas
    if (found) return found; // Retorna a subpasta encontrada
  }
  return null; // Retorna null se não encontrar
};



// Navegar para dentro de uma pasta
const handleNavigateToFolder = (folderId) => {
  setCurrentFolderId(folderId);
};

// Navegar para a pasta pai
const handleNavigateBack = () => {
  if (currentFolderId === "root") return;
  const parentFolderId = folders
    .reduce((acc, folder) => [...acc, ...folder.subfolders], [])
    .find((sub) => sub.id === currentFolderId).parentId;
  setCurrentFolderId(parentFolderId || "root");
};



// Remove um passo do checklist
const handleRemoveStep = (index) => {
  setChecklistItems(checklistItems.filter((_, i) => i !== index));
};



const [uploadedFiles, setUploadedFiles] = useState([]);
const [documentName, setDocumentName] = useState("");

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file && documentName.trim()) {
    setUploadedFiles([
      ...uploadedFiles,
      { name: documentName.trim(), file },
    ]);
    setDocumentName(""); // Limpar o campo do nome do documento
    event.target.value = ""; // Resetar o input de arquivo
  } else {
    alert("Por favor, insira o nome do documento antes de fazer o upload.");
  }
};


  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return renderInformacoesGerais();
      case 1:
        return renderEntregasMensais();
      case 2:
        return renderPrazosConfiguracoes();
      case 3:
        return renderChecklist();
      case 4:
        return renderDocumentos();
      case 5:
        return renderNotificacoes();
      case 6:
        return renderFinanceiro();
      default:
        return null;
    }
  };
  



  const [formData, setFormData] = useState({
    codigo: '',
    classificacao: '',
    nomeObrigacao: '',
    minimome: '',
    departamento: '',
    tipoServico: '',
    grupoServico: '', // Inicializado como string vazia
  });
  

 
  

  const meses = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro',
  ];

  const departamentos = [
    { id: '1', nome: 'Departamento A' },
    { id: '2', nome: 'Departamento B' },
  ];

  const tiposServico = [
    { id: '1', nome: 'Serviço X' },
    { id: '2', nome: 'Serviço Y' },
  ];

  const gruposServico = [
    { id: '1', nome: 'Grupo 1' },
    { id: '2', nome: 'Grupo 2' },
  ];

  const handleTabChange = (event, newValue) => setActiveTab(newValue);
  const [activeTab, setActiveTab] = useState(0); // Estado inicial da aba


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value !== undefined ? value : '', // Previne valores indefinidos
    }));
  };
  
  

  const renderInformacoesGerais = () => (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>
        Informações Gerais
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Código"
            name="codigo"
            value={formData.codigo}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Classificação"
            name="classificacao"
            value={formData.classificacao}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Mininome"
            name="minimome"
            value={formData.minimome}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label="Nome da Obrigação"
            name="nomeObrigacao"
            value={formData.nomeObrigacao}
            onChange={handleChange}
          />
        </Grid>
      

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Departamento</InputLabel>
            <Select
              name="departamento"
              value={formData.departamento}
              onChange={handleChange}
            >
              {departamentos.map((dep) => (
                <MenuItem key={dep.id} value={dep.id}>
                  {dep.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Tipo do Serviço</InputLabel>
            <Select
              name="tipoServico"
              value={formData.tipoServico}
              onChange={handleChange}
            >
              {tiposServico.map((tipo) => (
                <MenuItem key={tipo.id} value={tipo.id}>
                  {tipo.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
  <InputLabel>Grupo do Serviço</InputLabel>
  <Select
    name="grupoServico"
    value={formData.grupoServico || ''} // Garante que nunca será undefined
    onChange={handleChange}
  >
    {gruposServico.map((grupo) => (
      <MenuItem key={grupo.id} value={grupo.id}>
        {grupo.nome}
      </MenuItem>
    ))}
  </Select>
</FormControl>

        </Grid>
      </Grid>
      
    </Box>
  );

  const renderEntregasMensais = () => (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>
        Entregas Mensais
      </Typography>
      <Grid container spacing={2}>
        {meses.map((mes, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <FormControl fullWidth>
              <InputLabel>{`Entrega ${mes}`}</InputLabel>
              <Select>
                <MenuItem value="Não tem">Não tem</MenuItem>
                {Array.from({ length: 31 }).map((_, day) => (
                  <MenuItem value={day + 1} key={day}>
                    Todo dia {day + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </Box>
  );



  const renderChecklist = () => (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>
        Checklist
      </Typography>
  
      {/* Campo para adicionar novo passo */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <TextField
          fullWidth
          label="Novo passo"
          value={newStep}
          onChange={(e) => setNewStep(e.target.value)}
          sx={{ mr: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleAddStep}>
          Adicionar
        </Button>
      </Box>
  
      {/* Lista de passos */}
      {checklistItems.length > 0 ? (
        <Box>
          {checklistItems.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1,
                p: 1,
                backgroundColor: "#f9f9f9",
                borderRadius: 1,
              }}
            >
              <Typography>{item}</Typography>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => handleRemoveStep(index)}
              >
                Remover
              </Button>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography>Nenhum passo adicionado ainda.</Typography>
      )}
    </Box>
  );
  
  const renderDocumentos = () => {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2, color: "#1976d2", fontWeight: "bold" }}>
          Documentos
        </Typography>
  
        {/* Input para upload do arquivo */}
        <Box sx={{ mb: 3 }}>
          <Button
            variant="contained"
            component="label"
            sx={{ mr: 2 }}
          >
            Fazer Upload de PDF
            <input
              type="file"
              hidden
              accept="application/pdf"
              onChange={handleTestFileUpload}
            />
          </Button>
          {loading && <Typography>Processando arquivo...</Typography>}
        </Box>
  
        {/* Exibição dos dados extraídos */}
        {pdfData ? (
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1">
              <strong>Tipo de Documento:</strong> {pdfData.tipoDocumento || "Desconhecido"}
            </Typography>
  
            {pdfData.textoExtraido ? (
              <>
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  <strong>Texto Extraído:</strong>
                </Typography>
                <Typography sx={{ whiteSpace: "pre-wrap", mt: 1 }}>
                  {pdfData.textoExtraido.slice(0, 300)}...
                </Typography>
              </>
            ) : (
              <Typography sx={{ mt: 2 }}>Nenhum texto extraído do documento.</Typography>
            )}
  
            {/* Renderização condicional baseada no tipo do documento */}
            {pdfData.tipoDocumento === "Nota Fiscal de Serviço" && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  <strong>Detalhes da Nota Fiscal:</strong>
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography><strong>CNPJ Emitente:</strong> {pdfData.cnpj || "N/A"}</Typography>
                  <Typography><strong>Razão Social Emitente:</strong> {pdfData.razaoSocial || "N/A"}</Typography>
                  <Typography><strong>Valor Total:</strong> {pdfData.valor || "N/A"}</Typography>
                  <Typography><strong>Data de Emissão:</strong> {pdfData.dataEmissao || "N/A"}</Typography>
                </Box>
              </Box>
            )}
  
            {pdfData.tipoDocumento === "Guia da Previdência Social (GPS)" && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  <strong>Detalhes da Guia da Previdência Social:</strong>
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography><strong>Código de Pagamento:</strong> {pdfData.codigoPagamento || "N/A"}</Typography>
                  <Typography><strong>Competência:</strong> {pdfData.competencia || "N/A"}</Typography>
                  <Typography><strong>Identificador:</strong> {pdfData.identificador || "N/A"}</Typography>
                  <Typography><strong>Razão Social:</strong> {pdfData.razaoSocial || "N/A"}</Typography>
                  <Typography><strong>Valor INSS:</strong> {pdfData.valorINSS || "N/A"}</Typography>
                  <Typography><strong>Data de Vencimento:</strong> {pdfData.vencimento || "N/A"}</Typography>
                  <Typography><strong>Total:</strong> {pdfData.total || "N/A"}</Typography>
                </Box>
              </Box>
            )}
  
            {pdfData.tipoDocumento === "Outro Documento" && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  <strong>Detalhes do Documento:</strong>
                </Typography>
                <Typography sx={{ mt: 1 }}>Nenhum detalhe específico para este tipo de documento.</Typography>
              </Box>
            )}
          </Box>
        ) : (
          <Typography>Nenhum documento processado ainda.</Typography>
        )}
      </Box>
    );
  };
  
  


  const renderNotificacoes = () => (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>
        Notificações
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Canal de Notificação</InputLabel>
        <Select>
          <MenuItem value="whatsapp">WhatsApp</MenuItem>
          <MenuItem value="email">E-mail</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Template</InputLabel>
        <Select>
          <MenuItem value="template1">Template 1</MenuItem>
          <MenuItem value="template2">Template 2</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
  
  const renderFinanceiro = () => (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>
        Financeiro
      </Typography>
      <TextField
        fullWidth
        label="Valor do Serviço"
        type="number"
        sx={{ mb: 2 }}
      />
      <FormControl fullWidth>
        <InputLabel>Tipo de Pagamento</InputLabel>
        <Select>
          <MenuItem value="mensal">Mensal</MenuItem>
          <MenuItem value="unico">Único</MenuItem>
          <MenuItem value="anual">Anual</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
  
  const renderPrazosConfiguracoes = () => (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>
        Prazos e Configurações
      </Typography>
      <Grid container spacing={3} sx={{ mt: 1 }}>
         
        <Grid item xs={12} sm={6} md={4}>
                                        <TextField fullWidth label="Quantidade de dias para antecipar a entrega ao cliente" variant="outlined" />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField fullWidth label="Quantidade de dias para iniciar o serviço" variant="outlined" />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Tipo dos dias Antes</InputLabel>
                                            <Select label="Tipo dos dias Antes">
                                                <MenuItem value="Dias úteis">Dias úteis</MenuItem>
                                                <MenuItem value="Dias corridos">Dias corridos</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Prazos fixos em dias não úteis</InputLabel>
                                            <Select label="Prazos fixos em dias não úteis">
                                                <MenuItem value="Antecipar para o dia útil anterior">Antecipar para o dia útil anterior</MenuItem>
                                                <MenuItem value="Postergar para o próximo dia útil">Postergar para o próximo dia útil</MenuItem>
                                                <MenuItem value="Manter o dia exato">Manter o dia exato</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Sábado é útil?</InputLabel>
                                            <Select label="Sábado é útil?">
                                                <MenuItem value="N">Não</MenuItem>
                                                <MenuItem value="S">Sim</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Competência referente a</InputLabel>
                                            <Select label="Competência referente a">
                                                <MenuItem value="Mês anterior">Mês anterior</MenuItem>
                                                <MenuItem value="2 meses antes">2 meses antes</MenuItem>
                                                <MenuItem value="3 meses antes">3 meses antes</MenuItem>
                                                <MenuItem value="Ano anterior">Ano anterior</MenuItem>
                                                <MenuItem value="Ano atual">Ano atual</MenuItem>
                                                <MenuItem value="Mês atual">Mês atual</MenuItem>
                                                <MenuItem value="Mês seguinte">Mês seguinte</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Exigir Robô?</InputLabel>
                                            <Select label="Exigir Robô?">
                                                <MenuItem value="N">Não</MenuItem>
                                                <MenuItem value="S">Sim</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Passível de multa?</InputLabel>
                                            <Select label="Passível de multa?">
                                                <MenuItem value="N">Não</MenuItem>
                                                <MenuItem value="S">Sim</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Alerta guia não lida?</InputLabel>
                                            <Select label="Alerta guia não lida?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Obrigatório Check List?</InputLabel>
                                            <Select label="Obrigatório Check List?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Esfera</InputLabel>
                                            <Select label="Esfera">
                                                <MenuItem value="Federal">Federal</MenuItem>
                                                <MenuItem value="Estadual">Estadual</MenuItem>
                                                <MenuItem value="Municipal">Municipal</MenuItem>
                                                <MenuItem value="Interno">Interno</MenuItem>
                                                <MenuItem value="Outros">Outros</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Notificar o Cliente?</InputLabel>
                                            <Select label="Notificar o Cliente?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Serviço liberado no APP do cliente?</InputLabel>
                                            <Select label="Serviço liberado no APP do cliente?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Gerar para</InputLabel>
                                            <Select label="Gerar para">
                                                <MenuItem value="Matriz/Filial">Matriz/Filial</MenuItem>
                                                <MenuItem value="Apenas Matriz">Apenas Matriz</MenuItem>
                                                <MenuItem value="Apenas Filial">Apenas Filial</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Ativa</InputLabel>
                                            <Select label="Ativa">
                                                <MenuItem value="Sim">Sim</MenuItem>
                                                <MenuItem value="Não">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={8}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Baixar automaticamente a tarefa ao concluir as atividades</InputLabel>
                                            <Select label="Ativa">
                                                <MenuItem value="Sim">Sim</MenuItem>
                                                <MenuItem value="Não">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

         

        </Grid>
    </Box>
  );
<Modal
  open={openModal}
  onClose={() => setOpenModal(false)} // Fecha o modal sem confirmar
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
    }}
  >
    <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
      Atenção!
    </Typography>
    <Typography id="modal-description" sx={{ mb: 3 }}>
      Ao mudar a tarefa, os dados atuais serão perdidos. Deseja continuar?
    </Typography>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
      <Button onClick={() => setOpenModal(false)} variant="outlined">
        Cancelar
      </Button>
      <Button
        onClick={() => {
          setSelectedOption(pendingOption); // Atualiza a seleção
          setPendingOption(null); // Limpa a seleção pendente
          setOpenModal(false); // Fecha o modal
        }}
        variant="contained"
        color="error"
      >
        Confirmar
      </Button>
    </Box>
  </Box>
</Modal>





  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ p: 3, mb: 2, borderRadius: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Escolha a Tarefa
            </Typography>
            <RadioGroup
  row
  value={selectedOption}
  onChange={handleRadioChange} // Usa a função corrigida
>
  <FormControlLabel value="obrigacoes" control={<Radio />} label="Obrigações Recorrentes" />
  <FormControlLabel value="controle" control={<Radio />} label="Controle" />
  <FormControlLabel value="ordem-servico" control={<Radio />} label="Ordem de Serviço" />
  <FormControlLabel value="parcelamento" control={<Radio />} label="Parcelamento" />
  <FormControlLabel value="os-obrigacoes" control={<Radio />} label="Obrigações Recorrentes/Ordem de Serviço" />


</RadioGroup>






          </Paper>
        </Grid>

        {selectedOption && (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: 2,
                backgroundColor: '#fff',
              }}
            >
              {/* Barra Lateral de Abas */}
              <Box
                sx={{
                  minWidth: 240,
                  borderRight: '1px solid #ddd',
                  backgroundColor: '#f9f9f9',
                }}
              >
               <Tabs
  orientation="vertical"
  value={activeTab} // Usa o estado activeTab
  onChange={handleTabChange} // Atualiza o estado quando a aba muda
  sx={{
    '.MuiTab-root': {
      textTransform: 'none',
      padding: '12px 16px',
      justifyContent: 'flex-start',
      '&:hover': {
        backgroundColor: '#eee',
      },
    },
    '.Mui-selected': {
      backgroundColor: '#fff',
      color: '#8c52ff',
      fontWeight: 'bold',
    },
  }}
>
<Tab icon={<Person />} iconPosition="start" label="Informações Gerais" />
<Tab icon={<Event />} iconPosition="start" label="Entregas Mensais" />
<Tab icon={<Settings />} iconPosition="start" label="Prazos e Configurações" />
<Tab icon={<CheckCircle />} iconPosition="start" label="Checklist" />
<Tab icon={<Folder />} iconPosition="start" label="Documentos" />
<Tab icon={<Notifications />} iconPosition="start" label="Notificações" />
<Tab icon={<AttachMoney />} iconPosition="start" label="Financeiro" />




</Tabs>

              </Box>

              <Box sx={{ flex: 1, p: 3 }}>{renderTabContent()}</Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>

    
  );
};

export default NovaTarefa;
