import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { NearMeOutlined, Print } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const generateObligationsData = (numClientes) => {
  const obligations = ['Imposto de Renda', 'ISS', 'ICMS', 'Folha de Pagamento', 'Contribuições'];
  return Array.from({ length: numClientes }).map((_, i) => ({
    id: i + 1,
    cliente: `Cliente ${i + 1}`,
    obrigacao: obligations[i % obligations.length],
    vencimento: `2024-11-${(i % 30) + 1}`,
    status: i % 2 === 0 ? 'Pendente' : 'Concluído',
  }));
};

const Obrigacoes = () => {
  const [data] = useState(generateObligationsData(10)); // Exemplo com 10 linhas
  const [modalGerarOpen, setModalGerarOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const selectedCount = useMemo(() => Object.keys(rowSelection).length, [rowSelection]);
  const navigate = useNavigate();

  const [selectedFilters, setSelectedFilters] = useState({
    obrigacoes: false,
    controle: false,
    ordemServico: false,
    obrigacoesEOrdem: false,
    parcelamento: false,
  });

  const [newTask, setNewTask] = useState({
    tarefa: '',
    periodoInicial: '',
    periodoFinal: '',
    renovarNaoIniciadas: false,
    alterarVencimento: false,
    novaDataVencimento: '',
    transferir: false,
    transferirPara: '',
  });

  const handleFilterChange = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      [event.target.name]: event.target.checked,
    });
  };

  const handleGerarOpen = () => {
    const selectedRows = Object.keys(rowSelection).map((key) => data[key]);
    const tarefaNome = selectedRows.map((row) => row.obrigacao).join(', ');
    setNewTask({ ...newTask, tarefa: tarefaNome });
    setModalGerarOpen(true);
  };

  const handleGerarClose = () => {
    setModalGerarOpen(false);
    setNewTask({
      tarefa: '',
      periodoInicial: '',
      periodoFinal: '',
      renovarNaoIniciadas: false,
      alterarVencimento: false,
      novaDataVencimento: '',
      transferir: false,
      transferirPara: '',
    });
  };

  const columns = useMemo(() => [
    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'cliente', header: 'Cliente' },
    { accessorKey: 'obrigacao', header: 'Obrigação' },
    { accessorKey: 'vencimento', header: 'Vencimento' },
    { accessorKey: 'status', header: 'Status' },
  ], []);

  return (
    <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      {/* Caixa dos Filtros */}
      <Box
        sx={{
          mb: 4,
          p: 3,
          backgroundColor: '#fff',
          borderRadius: 2,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h6" sx={{ mb: 1 }}>
          Filtros
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: 'gray' }}>
          Selecione os filtros abaixo para ajustar as obrigações exibidas na tabela:
        </Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                name="obrigacoes"
                checked={selectedFilters.obrigacoes}
                onChange={handleFilterChange}
              />
            }
            label="Obrigações Recorrentes"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="controle"
                checked={selectedFilters.controle}
                onChange={handleFilterChange}
              />
            }
            label="Controle"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="ordemServico"
                checked={selectedFilters.ordemServico}
                onChange={handleFilterChange}
              />
            }
            label="Ordem de Serviço"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="obrigacoesEOrdem"
                checked={selectedFilters.obrigacoesEOrdem}
                onChange={handleFilterChange}
              />
            }
            label="Obrigações Recorrentes e Ordem de Serviço"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="parcelamento"
                checked={selectedFilters.parcelamento}
                onChange={handleFilterChange}
              />
            }
            label="Parcelamento"
          />
        </FormGroup>
      </Box>

      {/* Botões */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate('/nova-obrigacoes')}
            startIcon={<NearMeOutlined />}
            sx={{ textTransform: 'none' }}
          >
            Adicionar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGerarOpen}
            startIcon={<NearMeOutlined />}
            sx={{ textTransform: 'none' }}
          >
            Gerar
          </Button>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => console.log('Export PDF')}
          startIcon={<Print />}
          sx={{ textTransform: 'none' }}
        >
          Imprimir
        </Button>
      </Box>

      {/* Tabela */}
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowSelection
        onRowSelectionChange={setRowSelection}
        state={{ rowSelection }}
      />

      {/* Modal */}
      <Modal open={modalGerarOpen} onClose={handleGerarClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" mb={2}>
            Gerar Nova Tarefa
          </Typography>
          <TextField
            fullWidth
            label="Nome da Tarefa"
            value={newTask.tarefa}
            InputProps={{ readOnly: true }}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
              type="date"
              label="Período Inicial"
              value={newTask.periodoInicial}
              onChange={(e) => setNewTask({ ...newTask, periodoInicial: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              type="date"
              label="Período Final"
              value={newTask.periodoFinal}
              onChange={(e) => setNewTask({ ...newTask, periodoFinal: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 4 }}>
            <Button variant="outlined" color="secondary" onClick={handleGerarClose}>
              Fechar
            </Button>
            <Button variant="contained" color="primary">
              Gerar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Obrigacoes;
