import React, { useState } from 'react';
import {
  Button,
  TextField,
  Box,
  Typography,
  Grid,
  Paper,
  Alert,
  Modal,
  Backdrop,
  Fade,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import logo from './bg_logo.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState('');
  const [recoveryError, setRecoveryError] = useState('');
  const [recoverySuccess, setRecoverySuccess] = useState('');
  const navigate = useNavigate();

  // Função de login
  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (!email || !senha) {
      setError('Por favor, preencha todos os campos.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, { email, senha });

      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('usuario', JSON.stringify(response.data.usuario));
        navigate('/dashboard');
      } else {
        setError('Credenciais inválidas.');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError('Credenciais incorretas. Tente novamente.');
      } else if (error.response && error.response.status === 404) {
        setError('Usuário não encontrado.');
      } else {
        setError('Erro de conexão com o servidor. Tente novamente mais tarde.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Função para abrir o modal de recuperação
  const handleForgotPassword = () => {
    setIsModalOpen(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRecoveryEmail('');
    setRecoveryError('');
    setRecoverySuccess('');
  };

  // Função para envio de recuperação de senha
  const handleSendRecoveryEmail = async () => {
    if (!recoveryEmail) {
      setRecoveryError('Informe o e-mail para recuperação.');
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/recuperacao`, { email: recoveryEmail });
      setRecoverySuccess(`Instruções de recuperação enviadas para: ${recoveryEmail}`);
      setRecoveryError('');
    } catch (error) {
      setRecoveryError('Erro ao enviar e-mail de recuperação. Tente novamente.');
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      {/* Painel Esquerdo */}
      <Grid
  item
  xs={12}
  sm={4}
  md={6}
  sx={{
    backgroundColor: '#0D1B2A',
    backgroundImage: 'url("https://www.consead.com.br/wp-content/uploads/2018/03/contabilidade-e-gestao-financeira-ha-diferencas.jpeg")', // Substitua pelo link ou caminho da imagem desejada
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 3,
    textAlign: 'center',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(13, 27, 42, 0.8)', // Transparência sobre a imagem
      zIndex: 1,
    },
    zIndex: 2, // Para que o conteúdo fique acima da sobreposição
  }}
>
  <Typography component="h2" variant="h4" gutterBottom sx={{ zIndex: 2 }}>
    TORNE SUA CONTABILIDADE MAIS ESTRATÉGICA
  </Typography>
  <Typography variant="body1" sx={{ mb: 3, zIndex: 2 }}>
    Indique amigos e amplie sua rede de negócios com <strong>+20 CNPJs</strong> no seu plano!
  </Typography>
  <Button
    variant="contained"
    sx={{
      backgroundColor: '#F0B429',
      '&:hover': {
        backgroundColor: '#C88F11',
      },
      px: 4,
      py: 1.5,
      fontSize: 16,
      borderRadius: '25px',
      zIndex: 2,
    }}
  >
    INDICAR AMIGOS
  </Button>
</Grid>



      {/* Painel Direito */}
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 4,
          backgroundColor: '#fdfdfd',
        }}
      >
        <Box
          sx={{
            width: '80%',
            maxWidth: 400,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} alt="Logo" style={{ maxWidth: '150px', marginBottom: '20px' }} />
          <Typography align="center" sx={{ mb: 3, color: '#8D99AE' }}>
            Entre com e-mail e senha
          </Typography>
          <Box component="form" onSubmit={handleLogin}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ backgroundColor: '#f5f5f5', borderRadius: '8px' }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              sx={{ backgroundColor: '#f5f5f5', borderRadius: '8px' }}
            />
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#F0B429',
                color: '#fff',
                '&:hover': { backgroundColor: '#C88F11' },
                padding: '10px 0',
                borderRadius: '25px',
              }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'ENTRAR'}
            </Button>
          </Box>
          <Button
            fullWidth
            color="secondary"
            onClick={handleForgotPassword}
            sx={{
              mt: 2,
              color: '#8D99AE',
              textTransform: 'none',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            Esqueceu sua senha? Clique aqui
          </Button>
        </Box>
      </Grid>

      {/* Modal de Recuperação de Senha */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isModalOpen}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: '8px',
              boxShadow: 24,
              p: 4,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'grey.500',
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              Recuperação de Senha
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="recovery-email"
              label="Informe seu e-mail"
              name="recovery-email"
              autoComplete="email"
              value={recoveryEmail}
              onChange={(e) => setRecoveryEmail(e.target.value)}
              error={!!recoveryError}
              helperText={recoveryError}
            />
            {recoverySuccess && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {recoverySuccess}
              </Alert>
            )}
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 2, backgroundColor: '#F0B429', '&:hover': { backgroundColor: '#C88F11' } }}
              onClick={handleSendRecoveryEmail}
            >
              Enviar Instruções
            </Button>
            <Button
              fullWidth
              onClick={handleCloseModal}
              sx={{ mt: 1, color: '#8D99AE', textTransform: 'none', '&:hover': { textDecoration: 'underline' } }}
            >
              Cancelar
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Grid>
  );
};

export default Login;
