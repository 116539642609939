import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  Tooltip,
  Modal,
  Box,
  Typography,
  Button,
} from '@mui/material';

import {
  Dashboard as DashboardIcon,
  Business as BusinessIcon,
  Favorite as FavoriteIcon,
  ListAlt as ListAltIcon,
  Settings as SettingsIcon,
  ExpandLess,
  ExpandMore,
  Delete as DeleteIcon,
  Edit as EditIcon,
  AddCircle as AddCircleIcon,
  ClearAll as ClearAllIcon,
  BarChart as BarChartIcon,
  SwapHoriz as SwapHorizIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  Work as WorkIcon,
  Group as GroupIcon,
  StarBorder as StarBorderIcon,
  TableBar,
  CalendarToday as CalendarIcon,
  WhatsApp as WhatsAppIcon,
  ExitToApp as ExitToAppIcon,
  Star as StarIcon,
  Assignment,
  Build,
  Gavel,
  Tune,
  Analytics,
} from '@mui/icons-material';

import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import { DoneAll as DoneAllIcon } from '@mui/icons-material';
import { MailOutline as MailOutlineIcon } from '@mui/icons-material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';



const Sidebar = ({ isSidebarOpen }) => {
  const [isConfigOpen, setIsConfigOpen] = useState(false); // Submenu Configurações
  const [isConfigOpenTabela, setIsTabelaOpen] = useState(false); // Submenu Configurações

  const [isMultiEmpresaOpen, setIsMultiEmpresaOpen] = useState(false); // Submenu Multi-Empresa
  const [tipoUsuario, setTipoUsuario] = useState(''); // Estado para armazenar o tipo de usuário
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false); // Estado para abrir o modal de logout
  const navigate = useNavigate();
  const location = useLocation(); // Para verificar o caminho atual e aplicar o estado ativo.

  // Alterna a abertura dos submenus
  const handleConfigClick = () => setIsConfigOpen(!isConfigOpen);
  const handleTabelaClick = () => setIsTabelaOpen(!isConfigOpenTabela);

  const [isAtividadesOpen, setIsAtividadesOpen] = useState(false); // Estado para Atividades
  const [isTabelasOpen, setIsTabelasOpen] = useState(false); // Estado para Tabelas
  const handleAtividadesClick = () => setIsAtividadesOpen(!isAtividadesOpen); // Alternar Atividades
  const handleTabelasClick = () => setIsTabelasOpen(!isTabelasOpen); // Alternar Tabelas





  const handleMultiEmpresaClick = () => setIsMultiEmpresaOpen(!isMultiEmpresaOpen);

  const iconColor = '#fff'; // Ícones com cor laranja
  const textColor = '#fff'; // Texto branco
  const activeItemColor = '#FFA500'; // Cor de fundo para item ativo

  // Pega o tipo de usuário do localStorage (superadmin ou outro)
  useEffect(() => {
    const usuario = JSON.parse(localStorage.getItem('usuario'));
    if (usuario && usuario.tipo_usuario) {
      setTipoUsuario(usuario.tipo_usuario);
    }
  }, []);

  // Função para executar o logout
  const handleLogout = () => {
    localStorage.removeItem('token');       // Remove o token
    localStorage.removeItem('usuario');     // Remove os dados do usuário
    setLogoutModalOpen(false);              // Fecha o modal
    navigate('/');                          // Redireciona para a página de login
  };

  // Função para fechar o modal de logout
  const handleCloseLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isSidebarOpen ? 240 : 80,
        transition: 'width 0.3s ease',
        '& .MuiDrawer-paper': {
          width: isSidebarOpen ? 240 : 80,
          top: 64,
          height: 'calc(100vh - 64px)',
          transition: 'width 0.3s ease',
          backgroundColor: '#211b4b', // Fundo roxo escuro
          color: textColor,
          '::-webkit-scrollbar': { width: '8px' }, // Largura da barra de rolagem
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#FFA500', // Cor da barra de rolagem
            borderRadius: '4px',
          },
          '::-webkit-scrollbar-track': {
            backgroundColor: '#333', // Cor de fundo da barra de rolagem
          },
        },
      }}
    >
      <Divider sx={{ borderColor: '#444' }} />
      <List>
        {/* Dashboard */} 
        <SidebarItem
          icon={<DashboardIcon />}
          text="Dashboard"
          isOpen={isSidebarOpen}
          navigateTo="/dashboard"
          iconColor={iconColor}
          textColor={textColor}
          isActive={location.pathname === '/dashboard'}
        />
         {/* Painel de atividades */}
         <SidebarItem
          icon={<DesignServicesIcon   />} // Icone para Painel de Atividades
          text="Painel de Monitoramento"
          isOpen={isSidebarOpen}
          navigateTo="/painel-atividades"
          iconColor={iconColor}
          textColor={textColor}
          isActive={location.pathname === '/painel-atividades'} // Mantido corretamente
          />

          {/* Serviços */}
          <SidebarItem
          icon={<Build   />} // Icone para Painel de Atividades
          text="Central de Atividades"
          isOpen={isSidebarOpen}
          navigateTo="/servicos"
          iconColor={iconColor}
          textColor={textColor}
          isActive={location.pathname === '/servicos'} // Corrigido
          />
       

        {/* Obrigações */}
        <SidebarItem
          icon={<Gavel  />}
          text="Gestão de Tarefas"
          isOpen={isSidebarOpen}
          navigateTo="/Obrigacoes"
          iconColor={iconColor}
          textColor={textColor}
          isActive={location.pathname === '/Obrigacoes'}
        />
        {/* Clientes */}
        <SidebarItem
          icon={<BusinessIcon />}
          text="Gestão de Clientes"
          isOpen={isSidebarOpen}
          navigateTo="/clientes"
          iconColor={iconColor}
          textColor={textColor}
          isActive={location.pathname === '/clientes'}
        />
      

       {/* Tabelas - Somente visível para admin e superadmin */}
{(tipoUsuario === 'admin' || tipoUsuario === 'superadmin') && (
  <SidebarSubmenu
    icon={<Analytics />}
    text="Atividades"
    isOpen={isSidebarOpen}
    isSubmenuOpen={isAtividadesOpen}
    toggleSubmenu={handleAtividadesClick}
    submenuItems={[
      { text: 'Gerar Recorrentes', path: '/grupo-cliente', icon: <AddCircleIcon /> }, // Icone de adicionar
      { text: 'Alterar vencimento', path: '/grupo-servico', icon: <EditIcon /> }, // Icone de editar
      { text: 'Remover (Tarefa não iniciada)', path: '/status-cliente', icon: <DeleteIcon /> }, // Icone de deletar
      { text: 'Remover (Tarefa iniciadas, não concluidas)', path: '/status-complementar', icon: <RemoveCircleOutlineIcon /> }, // Icone de remover círculo
      { text: 'Remover (Todas)', path: '/regime-tributario', icon: <ClearAllIcon /> }, // Icone de limpar tudo
      { text: 'Transferir responsável', path: '/modelo-regime-tributario', icon: <SwapHorizIcon /> }, // Icone de transferir
      { text: 'Relatórios', path: '/escritorio', icon: <BarChartIcon /> }, // Icone de gráfico para relatórios
    ]}
    iconColor={iconColor}
    textColor={textColor}
    activePath={location.pathname}
  />
)}

         {/* Tabelas - Somente visível para admin e superadmin */}
         {(tipoUsuario === 'admin' || tipoUsuario === 'superadmin') && (
          <SidebarSubmenu
            icon={<Analytics />}
            text="Tabelas"
            isOpen={isSidebarOpen}
            isSubmenuOpen={isTabelasOpen}
            toggleSubmenu={handleTabelasClick}
            submenuItems={[
              { text: 'Grupo de cliente', path: '/grupo-cliente', icon: <GroupIcon /> },
              { text: 'Grupo de serviço', path: '/grupo-servico', icon: <WorkIcon /> },
              { text: 'Status principal do cliente', path: '/status-cliente', icon: <StarBorderIcon /> },
              { text: 'Status complementar do cliente', path: '/status-complementar', icon: <VerifiedRoundedIcon /> },
              { text: 'Regime Tributário', path: '/regime-tributario', icon: <SummarizeRoundedIcon /> },
              { text: 'Modelo Regime Tributário', path: '/modelo-regime-tributario', icon: <SummarizeRoundedIcon /> },

              
              { text: 'Escritório', path: '/escritorio', icon: <BusinessIcon /> },
              { text: 'Categoria do cliente', path: '/categoria-cliente', icon: <GroupIcon /> },
              { text: 'Segmento da empresa', path: '/segmento-empresa', icon: <BusinessIcon /> },
              { text: 'Tipo do cliente', path: '/tipo-cliente', icon: <GroupIcon /> },
              { text: 'Tipo de serviço', path: '/tipo-servico', icon: <WorkIcon /> },
              { text: 'Calendário de feriados', path: '/calendario-feriados', icon: <CalendarIcon /> },
            ]}
            iconColor={iconColor}
            textColor={textColor}
            activePath={location.pathname}
          />
        )}

        {/* Configurações - Somente visível para admin e superadmin */}
        {(tipoUsuario === 'admin' || tipoUsuario === 'superadmin') && (
          <SidebarSubmenu
            icon={<SettingsIcon />}
            text="Configurações"
            isOpen={isSidebarOpen}
            isSubmenuOpen={isConfigOpen}
            toggleSubmenu={handleConfigClick}
            submenuItems={[
              { text: 'Integrações', path: '/integracoes', icon: <SettingsIcon /> },
              { text: 'Departamento', path: '/departamento', icon: <WorkIcon /> },
              { text: 'Usuários e permissões', path: '/usuarios', icon: <GroupIcon /> },
              { text: 'Horário de acesso', path: '/horario-acesso', icon: <CalendarIcon /> },
              { text: 'Template de WhatsApp', path: '/template-whatsapp', icon: <WhatsAppIcon /> },
              { text: 'Template de E-mail', path: '/template-email', icon: <MailOutlineIcon /> },
            ]}
            iconColor={iconColor}
            textColor={textColor}
            activePath={location.pathname}
          />
        )}

        {/* Multi-Empresa (Apenas para superadmin) */}
        {tipoUsuario === 'superadmin' && (
          <SidebarSubmenu
            icon={<BusinessIcon />}
            text="Multi-Empresa"
            isOpen={isSidebarOpen}
            isSubmenuOpen={isMultiEmpresaOpen}
            toggleSubmenu={handleMultiEmpresaClick}
            submenuItems={[
              { text: 'Empresas', path: '/empresas', icon: <BusinessIcon /> },
            ]}
            iconColor={iconColor}
            textColor={textColor}
            activePath={location.pathname}
          />
        )}
      </List>

      <Divider sx={{ borderColor: '#444' }} />

      {/* Avaliação e Sair */}
      <List>
        <SidebarItem
          icon={<StarIcon />}
          text="Avalie-nos"
          isOpen={isSidebarOpen}
          onClick={() => {}}
          iconColor={iconColor}
          textColor={textColor}
        />
        <SidebarItem
          icon={<ExitToAppIcon />}
          text="Sair"
          isOpen={isSidebarOpen}
          onClick={() => setLogoutModalOpen(true)} // Abre o modal de logout ao clicar
          iconColor={iconColor}
          textColor={textColor}
        />
      </List>

      {/* Modal de Logout */}
      <Modal
        open={isLogoutModalOpen}
        onClose={handleCloseLogoutModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Encerrando sessão
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Você está prestes a encerrar sua sessão. Deseja continuar?
          </Typography>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button variant="contained" color="primary" onClick={handleLogout}>
              Sim
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCloseLogoutModal}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Drawer>
  );
};

// Componente para itens simples
const SidebarItem = ({ icon, text, isOpen, navigateTo, onClick, iconColor, textColor, isActive }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) onClick();
    if (navigateTo) navigate(navigateTo);
  };

  return (
    <Tooltip title={!isOpen ? text : ''} placement="right">
      <ListItem
        button
        onClick={handleClick}
        sx={{
          justifyContent: isOpen ? 'initial' : 'center',
          backgroundColor: isActive ? 'orange' : 'transparent', // Fundo para item ativo
          '&:hover': {
            backgroundColor: 'orange',
          },
          paddingLeft: isOpen ? 2 : 0,
        }}
      >
        <ListItemIcon sx={{ color: iconColor, minWidth: isOpen ? 'auto' : 0 }}>{icon}</ListItemIcon>
        {isOpen && <ListItemText primary={text} sx={{ color: textColor, marginLeft: 2 }} />}
      </ListItem>
    </Tooltip>
  );
};

// Componente para submenus
const SidebarSubmenu = ({ icon, text, isOpen, isSubmenuOpen, toggleSubmenu, submenuItems, iconColor, textColor, activePath }) => {
  const navigate = useNavigate();

  return (
    <>
      <Tooltip title={!isOpen ? text : ''} placement="right">
        <ListItem
          button
          onClick={toggleSubmenu}
          sx={{
            justifyContent: isOpen ? 'initial' : 'center',
            '&:hover': {
              backgroundColor: 'orange',
            },
            paddingLeft: isOpen ? 2 : 0,
          }}
        >
          <ListItemIcon sx={{ color: iconColor, minWidth: isOpen ? 'auto' : 0 }}>{icon}</ListItemIcon>
          {isOpen && <ListItemText primary={text} sx={{ color: textColor, marginLeft: 2 }} />}
          {isOpen && (isSubmenuOpen ? <ExpandLess sx={{ color: iconColor }} /> : <ExpandMore sx={{ color: iconColor }} />)}
        </ListItem>
      </Tooltip>
      <Collapse in={isSubmenuOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {submenuItems.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => navigate(item.path)}
              sx={{
                pl: 4,
                backgroundColor: activePath === item.path ? '#333' : 'transparent', // Fundo para subitem ativo
                '&:hover': { backgroundColor: '#444' },
              }}
            >
              <ListItemIcon sx={{ color: iconColor }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} sx={{ color: textColor }} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default Sidebar;
