import React, { useMemo, useState, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Menu,
  MenuItem,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { Delete, Edit, SaveAlt, Print, Share, Email } from '@mui/icons-material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Visibility } from '@mui/icons-material';


const Clientes = () => {
  const [data, setData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [anchorEl, setAnchorEl] = useState(null); // Para o menu de compartilhar
  const navigate = useNavigate();


  useEffect(() => {
    console.log('Dados carregados:', data);
  }, [data]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('Token não encontrado. Faça login novamente.');
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/clientes`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const orderedData = (response.data.clientes || []).sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setData(orderedData);
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('Token não encontrado. Faça login novamente.');
          return;
        }
  
        const clientesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/clientes`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        const clientesData = clientesResponse.data;
  
        const enhancedData = await Promise.all(
          clientesData.map(async (cliente) => {
            const [classificacao, contatos, inscricoes, redesSociais, anotacoes, responsavel] =
              await Promise.all([
                axios.get(`${process.env.REACT_APP_API_URL}/api/classificacao/${cliente.id}`, {
                  headers: { Authorization: `Bearer ${token}` },
                }),
                axios.get(`${process.env.REACT_APP_API_URL}/api/contatos_cliente/${cliente.id}`, {
                  headers: { Authorization: `Bearer ${token}` },
                }),
                axios.get(`${process.env.REACT_APP_API_URL}/api/inscricoes_cliente/${cliente.id}`, {
                  headers: { Authorization: `Bearer ${token}` },
                }),
                axios.get(`${process.env.REACT_APP_API_URL}/api/redes_sociais_cliente/${cliente.id}`, {
                  headers: { Authorization: `Bearer ${token}` },
                }),
                axios.get(`${process.env.REACT_APP_API_URL}/api/anotacoes_cliente/${cliente.id}`, {
                  headers: { Authorization: `Bearer ${token}` },
                }),
                axios.get(`${process.env.REACT_APP_API_URL}/api/responsavel/${cliente.id}`, {
                  headers: { Authorization: `Bearer ${token}` },
                }),
              ]);
  
            return {
              ...cliente,
              classificacao: classificacao.data,
              contatos_cliente: contatos.data,
              inscricoes_cliente: inscricoes.data,
              redes_sociais_cliente: redesSociais.data,
              anotacoes_cliente: anotacoes.data,
              responsavel: responsavel.data,
            };
          })
        );
  
        setData(enhancedData);
      } catch (error) {
        console.error('Erro ao buscar dados relacionados:', error);
      }
    };
  
    fetchData();
  }, []);
  



  const exportCSV = () => {
    const csvData = data.map((cliente) => ({
      Nome: cliente.nome,
      Email: cliente.email,
      Telefone: cliente.telefone,
      Status: cliente.status,
    }));

    const csvString = [
      ['Nome', 'Email', 'Telefone', 'Status'],
      ...csvData.map((row) => [row.Nome, row.Email, row.Telefone, row.Status]),
    ]
      .map((e) => e.join(','))
      .join('\n');

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'clientes.csv';
    link.click();
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Lista de Clientes', 20, 10);
    doc.autoTable({
      head: [['Nome', 'Email', 'Telefone', 'Status']],
      body: data.map((cliente) => [cliente.nome, cliente.email, cliente.telefone, cliente.status]),
    });
    doc.save('clientes.pdf');
  };


 // Função para excluir cliente
 const handleDelete = async (clienteId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      setSnackbarMessage('Token não encontrado. Faça login novamente.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    await axios.delete(`${process.env.REACT_APP_API_URL}/api/clientes/${clienteId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setData((prevData) => prevData.filter((cliente) => cliente.id !== clienteId));
    setSnackbarMessage('Cliente removido com sucesso!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  } catch (error) {
    setSnackbarMessage('Erro ao remover cliente.');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  }
};




  const sendEmail = async () => {
    try {
      const emailContent = data.map(
        (cliente) => `Nome: ${cliente.nome}, Email: ${cliente.email}, Telefone: ${cliente.telefone}, Status: ${cliente.status}`
      ).join('\n');

      const token = localStorage.getItem('token');
      if (!token) {
        setSnackbarMessage('Token não encontrado. Faça login novamente.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/send-email`,
        { message: emailContent },
        {
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        }
      );

      setSnackbarMessage('E-mail enviado com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Erro ao enviar e-mail:', error);
      setSnackbarMessage('Erro ao enviar e-mail.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

 
  

  const handleShareWhatsAppWeb = () => {
    const message = 'Lista de Clientes:\n' + data.map(
      (cliente) => `- ${cliente.nome} (${cliente.email})`
    ).join('\n');

    const encodedMessage = encodeURIComponent(message);
    window.open(`https://web.whatsapp.com/send?text=${encodedMessage}`, '_blank');
    setAnchorEl(null);
  };

  const handleShareAtalk = () => {
    const message = 'Lista de Clientes:\n' + data.map(
      (cliente) => `- ${cliente.nome} (${cliente.email})`
    ).join('\n');

    const apiUrl = `https://api.atalk.com/send?message=${encodeURIComponent(message)}`;
    window.open(apiUrl, '_blank');
    setAnchorEl(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const columns = useMemo(() => [
    { accessorKey: 'nome', header: 'Nome' },
    { accessorKey: 'email', header: 'E-mail' },
    { accessorKey: 'telefone', header: 'Telefone' },
    { accessorKey: 'classificacao.tipo_cliente', header: 'Tipo Cliente' },
    { accessorKey: 'classificacao.status_cliente', header: 'Status Cliente' },
    { accessorKey: 'inscricao_tipo', header: 'Tipo Inscrição' },
    { accessorKey: 'inscricao_numero', header: 'Número Inscrição' },
    { accessorKey: 'contato_nome', header: 'Contato Principal' },
    { accessorKey: 'contato_email', header: 'E-mail Contato' },
    { accessorKey: 'rede_social_opcao', header: 'Rede Social' },
    { accessorKey: 'rede_social_url', header: 'URL Rede Social' },
    { accessorKey: 'anotacao_descricao', header: 'Descrição Anotação' },
    { accessorKey: 'anotacao_departamento', header: 'Departamento Anotação' },
    { accessorKey: 'responsavel_nome', header: 'Responsável' },
    { accessorKey: 'responsavel_cargo', header: 'Cargo do Responsável' },
    {
      
        header: 'Ações',
        size: 150,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
            <Tooltip title="Visualizar">
              <IconButton
                color="info"
                onClick={() => navigate(`/VisualizarCliente/${row.original.id}`)}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar">
              <IconButton
                color="primary"
                onClick={() => navigate(`/EditarCliente/${row.original.id}`)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remover">
              <IconButton color="error" onClick={() => handleDelete(row.original.id)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
      
  ], [navigate, handleDelete]);

  
  

  return (
    <Box sx={{ padding: 3 }}>
     <Box sx={{ marginBottom: 2, display: 'flex', justifyContent: 'space-between' }}>
  <Button
    variant="outlined"
    color="primary"
    onClick={() => navigate('/NovoCliente')}
  >
    Adicionar Cliente
  </Button>
  <Box sx={{ display: 'flex', gap: '8px' }}>
    <Button variant="contained" color="success" onClick={exportCSV} startIcon={<SaveAlt />}>
      Exportar CSV
    </Button>
    <Button variant="contained" color="info" onClick={exportPDF} startIcon={<SaveAlt />}>
      Exportar PDF
    </Button>
    <Button
      variant="contained"
      color="secondary"
      onClick={sendEmail}
      startIcon={<Email />}
    >
      Enviar por E-mail
    </Button>
     {/* Botão WhatsApp */}
  <Button
    variant="contained"
    color="primary"
    onClick={handleShareClick} // Abre o menu
    startIcon={<Share />}
  >
    WhatsApp
  </Button>
  <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleMenuClose}
  >
    <MenuItem onClick={handleShareWhatsAppWeb}>WhatsApp Web</MenuItem>
    <MenuItem onClick={handleShareAtalk}>Atalk</MenuItem>
    <MenuItem onClick={handleMenuClose}>API</MenuItem>
  </Menu>
  </Box>
</Box>


<MaterialReactTable
  columns={columns}
  data={data}
  enableStickyHeader
  enableColumnActions={true}
  enableColumnFilters={true} // Habilita filtros nas colunas
  enableSorting={true} // Permite ordenação
  enableGlobalFilter={true} // Filtro global
  enableRowSelection={true} // Permite seleção de linhas
  enableColumnResizing={true} // Permite redimensionar as colunas
  enableColumnDragging={true} // Permite arrastar/mover as colunas
  enablePagination={false} // Permite paginação
  enableDensityToggle={true} // Alterar densidade de exibição
  localization={{
    actions: 'Ações',
    cancel: 'Cancelar',
    save: 'Salvar',
    search: 'Pesquisar',
    searchPlaceholder: 'Digite para pesquisar...',
    showHideColumns: 'Mostrar/Ocultar Colunas',
    sortByColumnAsc: 'Ordenar Ascendente',
    sortByColumnDesc: 'Ordenar Descendente',
    columnDrag: 'Arraste para reordenar',
    columnResize: 'Arraste para redimensionar',
    selectedCountOfRowCountRowsSelected: 'Selecionado {selectedCount} de {rowCount}',
    noResultsFound: 'Nenhum resultado encontrado',
    rowsPerPage: 'Linhas por página',
    nextPage: 'Próxima página',
    previousPage: 'Página anterior',
    toggleDensity: 'Alternar densidade',
    exportCSV: 'Exportar CSV',
    exportPDF: 'Exportar PDF',
  }}
  initialState={{
    density: 'comfortable', // Exibição confortável
    showGlobalFilter: true, // Mostra o filtro global
    columnVisibility: {
      // Configure as colunas visíveis inicialmente
      'classificacao.tipo_cliente': true,
      'classificacao.status_cliente': false,
      'inscricao_tipo': false,
      'inscricao_numero': false,
      'contato_nome': false,
      'contato_email': false,
      'rede_social_opcao': false,
      'rede_social_url': false,
      'anotacao_descricao': false,
      'anotacao_departamento': false,
      'responsavel_nome': false,
      'responsavel_cargo': false,
    },
    pagination: { pageSize: 10 }, // 10 registros por página
  }}
  muiTableContainerProps={{
    sx: {
      maxHeight: '500px', // Ajuste a altura da tabela conforme necessário
      overflowX: 'auto', // Permite rolagem horizontal se necessário
    },
  }}
  
  muiTableBodyCellProps={{
    sx: {
      fontSize: '0.875rem',
      padding: '6px',
    },
  }}
  muiTableHeadCellProps={{
    sx: {
      fontSize: '0.9rem',
      padding: '12px',
    },
  }}
/>




      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Clientes;
