import React, { useState, useEffect } from 'react';
import {
  Box, Button, Table, TableHead, TableRow, TableCell, TableBody, Modal, TextField, Typography, Tooltip, IconButton, Snackbar
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';

const WhatsappTemplates = () => {
  const [templates, setTemplates] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateForm, setTemplateForm] = useState({ titulo: '', mensagem: '', arquivo_url: '' });
  const [imagePreview, setImagePreview] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    loadTemplates();
  }, []);

  const loadTemplates = () => {
    const token = localStorage.getItem('token');
    axios.get(`${process.env.REACT_APP_API_URL}/api/whatsapp-templates`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => setTemplates(response.data))
    .catch(() => console.error('Erro ao carregar templates de WhatsApp'));
  };

  const handleOpenModal = (type, template = null) => {
    setModalType(type);
    if (template) {
      setSelectedTemplate(template);
      setTemplateForm({ titulo: template.titulo, mensagem: template.mensagem, arquivo_url: template.arquivo_url });
      setImagePreview(template.imagem_url);
    } else {
      setSelectedTemplate(null);
      setTemplateForm({ titulo: '', mensagem: '', arquivo_url: '' });
      setImagePreview('');
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTemplate(null);
    setImagePreview('');
  };

  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('titulo', templateForm.titulo);
    formData.append('mensagem', templateForm.mensagem);
    formData.append('imagem', templateForm.imagem_url); // Imagem agora como arquivo
    formData.append('arquivo_url', templateForm.arquivo_url);

    const url = selectedTemplate ? `${process.env.REACT_APP_API_URL}/api/whatsapp-templates/${selectedTemplate.id}` : `${process.env.REACT_APP_API_URL}/api/whatsapp-templates`;

    const method = selectedTemplate ? 'put' : 'post';

    axios({
      method,
      url,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(() => {
      setSuccessMessage(`Template ${selectedTemplate ? 'atualizado' : 'criado'} com sucesso!`);
      setShowSnackbar(true);
      handleCloseModal();
      loadTemplates();
    })
    .catch(() => console.error('Erro ao salvar template'));
  };

  const handleDelete = (id) => {
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/api/whatsapp-templates/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(() => {
      setSuccessMessage('Template deletado com sucesso!');
      setShowSnackbar(true);
      loadTemplates();
    })
    .catch(() => console.error('Erro ao deletar template'));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setTemplateForm({ ...templateForm, imagem_url: file });
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => handleOpenModal('add')}>
        Adicionar Template de WhatsApp
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Título</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {templates.map(template => (
            <TableRow key={template.id}>
              <TableCell>{template.titulo}</TableCell>
              <TableCell>
                <Tooltip title="Editar">
                  <IconButton color="primary" onClick={() => handleOpenModal('edit', template)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                  <IconButton color="error" onClick={() => handleDelete(template.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal open={openModal} onClose={handleCloseModal} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ padding: 4, backgroundColor: 'white', width: '80%', height: '80%', borderRadius: '8px', position: 'relative', overflowY: 'auto', display: 'flex', gap: 4 }}>
          <IconButton onClick={handleCloseModal} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>

          <Box sx={{ width: '60%' }}>
            <Typography variant="h6">{modalType === 'add' ? 'Adicionar Template de WhatsApp' : 'Editar Template de WhatsApp'}</Typography>
            
            <TextField label="Título" fullWidth value={templateForm.titulo} onChange={(e) => setTemplateForm({ ...templateForm, titulo: e.target.value })} sx={{ marginBottom: 2 }} />
            
            <TextField label="Mensagem" fullWidth multiline rows={4} value={templateForm.mensagem} onChange={(e) => setTemplateForm({ ...templateForm, mensagem: e.target.value })} sx={{ marginBottom: 2 }} />
            
            {/* Input para selecionar a imagem */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button variant="contained" component="label" sx={{ marginBottom: 2 }}>
                Selecionar Imagem
                <input type="file" accept="image/*" hidden onChange={handleImageChange} />
              </Button>

              <Button variant="contained" color="success" onClick={handleSubmitForm} sx={{ marginBottom: 2 }}>
                {modalType === 'add' ? 'Adicionar' : 'Salvar'}
              </Button>
            </Box>
          </Box>

          {/* Prévia do celular no estilo WhatsApp */}
          <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h6">Prévia no WhatsApp</Typography>
            <Box
              sx={{
                width: '360px',
                height: '600px',
                backgroundImage: 'url(https://i.pinimg.com/originals/7b/1d/8e/7b1d8e865da2c11b788a21a0fb51d542.jpg)', // Imagem de fundo estilo WhatsApp
                backgroundSize: 'cover',
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                padding: '16px',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#DCF8C6',
                  borderRadius: '8px',
                  padding: '12px',
                  maxWidth: '75%',
                  marginBottom: '16px',
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
                  {templateForm.titulo}
                </Typography>
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Prévia Imagem"
                    style={{ width: '100%', height: 'auto', marginBottom: '8px' }}
                  />
                )}
                <Typography>{templateForm.mensagem}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={() => setShowSnackbar(false)} message={successMessage} />
    </Box>
  );
};

export default WhatsappTemplates;
